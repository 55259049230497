import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const MaximizeCampaign = ({ location }) => {
  const title = "Twitterキャンペーンの効果を最大化させる方法 6選"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="成功するTwitterキャンペーンの企画や景品を具体例で解説"
          pagedesc="成果の出るTwitterキャンペーンを検討中の担当者必見！販促効果の高いキャンペーンの設計方法・景品・バナー・開催前後の運用・PDCAまで、作業フローに沿ってくわしく解説します。無料相談も受付中。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/maximize-campaign.jpg"
          pagepath="/blog/maximize-campaign"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は10分で読むことができます
            </div>
            <h1>Twitterキャンペーンの効果を最大化させる方法 6選</h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">2021年9月24日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="ノートパソコンを一緒に覗き込んでいる2人の女性"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/maximize-campaign.jpg"
                className="w-full"
              />
            </figure>
            <p>こんにちは、PARKLoT マーケティング担当の島田旬子です！</p>
            <p>
              「<strong>どのようにキャンペーンを運営すればいいの？</strong>
              」と悩んでいる人も多いのではないでしょうか。
            </p>
            <p>非常に多くのお客様からいただきます、この質問。</p>
            <p>
              そこで今回は、PARKLoTで過去に開催したキャンペーン実績の傾向から分かった「キャンペーン成功の秘訣」をお話しようと思います。
            </p>
            <p>この記事は次のような人にオススメです！</p>
            <ul className="blog__border-list">
              <li>Twitterキャンペーンを企画中の方</li>
              <li>効果の高いキャンペーンを開催したいマーケティング担当者</li>
              <li>実店舗やECサイトのSNS担当者</li>
            </ul>
            <p>
              今回の記事では、Twitterキャンペーンの効果を最大化させる6つの方法をご紹介します。
            </p>
            <p>
              「<strong>どのような商品を設定すれば良いのか？</strong>
              」というプレゼント選びから始めて、当選・落選結果をお知らせするところまで、作業フローに沿って順番に解説します。
            </p>
            <p>
              この記事がお悩みの解決に役立ったら幸いです。
              <br />
              それではどうぞ！
            </p>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    1.自社商品（サービス）とプレゼントの親和性が重要
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id2" className="toc__link">
                        ・自社商品をプレゼントに設定する
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id3" className="toc__link">
                        ・実店舗がある場合はクーポンがおすすめ
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        ・商品の認知が低い場合は副賞にAmazonギフト券を
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        ・フォロワーが少ない場合、高額景品はNG
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id6" className="toc__link">
                    2.バナー画像はファーストビューにこだわる
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        ・動画はNG
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id8" className="toc__link">
                        ・おすすめはGIF画像
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id9" className="toc__link">
                    3.キャンペーンのリマインド投稿をする
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id10" className="toc__link">
                        ・期間中は毎日リマインド投稿
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id11" className="toc__link">
                        ・リツイートを代行する便利なサービス
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id12" className="toc__link">
                    4.キャンペーン投稿を「固定ツイート」に設定する
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id13" className="toc__link">
                    5.当選＆落選報告に返信する
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id14" className="toc__link">
                    6.継続的にキャンペーンを開催する
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id15" className="toc__link">
                    Twitterキャンペーンの費用でお悩みの方へ
                  </a>
                </li>
              </ol>
            </div>

            <div>
              <h2 id="id1">1.自社商品（サービス）とプレゼントの親和性が重要</h2>
              <p>
                Twitterキャンペーンを開催するときに、まず考えなければいけないのが「プレゼントの内容」です。キャンペーンへの参加を検討している人が、最も気にするポイントだといえるでしょう。
              </p>
              <p>
                プレゼント選びでは、自社商品（サービス）に合ったプレゼントを設定することが重要です。
              </p>
              <h3 id="id2">・自社商品をプレゼントに設定する</h3>
              <p>
                自社で商品（飲食物を含む）を販売、提供している場合は、それをプレゼントに設定した方がいいでしょう。
                <br />
                <strong>
                  自社商品に興味のあるユーザーを集客できるので、転売目的の参加者が少なくフォロワーの質が高まります。
                </strong>
              </p>
              <p>
                また、プレゼントを使って満足した参加者が、自社の商品を購入し新規顧客になる可能性もあります。
              </p>
              <p>
                既存のお客様にとっても、自分の好きなお店の商品は気になるので、顧客満足度の向上に繋がるうれしいプレゼントだといえるでしょう。
              </p>
              <h3 id="id3">・実店舗がある場合はクーポンがおすすめ</h3>
              <p>
                飲食店やアパレル店など実店舗がある場合は、プレゼントにクーポンを設定するのがオススメです。自店舗への来店を促すことで、色々な商品やメニューを見てもらうことができ、顧客の幅広いニーズに応えることが可能になります。
              </p>
              <p>
                クーポン配布のメリットは
                <a
                  href="https://www.park-lot.com/blog/coupon-prize/"
                  target="_blank"
                  rel="noreferrer"
                >
                  別の記事
                </a>
                でも解説しているので、そちらも参考にしてくださいね。
              </p>
              <h3 id="id4">・商品の認知が低い場合は副賞にAmazonギフト券を</h3>
              <p>
                商品（サービス）が世の中にあまり知られていない場合や、とにかく短期的にフォロワーを増やしたい場合は、副賞にAmazonギフト券を設定するのも有効です。
              </p>
              <p>
                日本におけるAmazonの利用者数は5,000万人を超えるといわれており、圧倒的なシェアを誇ります。Amazonギフト券は利用者たちにとって確実にうれしいプレゼントなので、幅広い層の参加者を集めることができます。
              </p>
              <p>
                <strong>
                  PARKLoTで過去に開催したキャンペーンでも、Amazonギフト券を副賞に設定したことで、平均で2〜3倍もフォロワーが増加しています。
                </strong>
                （多いときには4倍以上の伸び率を記録）
              </p>
              <p>
                参加者が増えることでリツイート数も増加し、商品（サービス）の認知度を高めることができます。リツイート画像を何度も見てもらうことにより、商品（サービス）が意識にすり込まれていくので、潜在的な顧客にアプローチしていくことが可能になります。
              </p>
              <p>
                あくまでもAmazonギフト券は副賞に設定するのがポイントです。メインのプレゼントには自社商品（サービス）を設定するようにしましょう。
              </p>
              <h3 id="id5">・フォロワーが少ない場合、高額景品はNG</h3>
              <p>
                高額な商品をプレゼントに設定する場合、アカウントのフォロワー数が少ないと怪しまれてしまいます。（1万円のギフト券やNintendo
                Switchなど）
              </p>
              <p>
                フィッシング詐欺だと疑われたりするので、
                <strong>
                  ギフト券は500〜1000円に設定した方が参加者が集まりやすくなります。
                </strong>
                <br />
                高額商品で一概に参加者が増えるわけではないので、注意が必要です。
              </p>
            </div>
            <div>
              <h2 id="id6">2.バナー画像はファーストビューにこだわる</h2>
              <p>
                プレゼントの内容が決まったら、次に必要になるのがキャンペーンを告知するためのバナーです。
              </p>
              <p>
                <strong>
                  「参加したい！」と思ってもらうためには、ファーストビューの「分かりやすさ」「伝わりやすさ」へ徹底的にこだわることが重要です。
                </strong>
              </p>
              <h3 id="id7">・動画はNG</h3>
              <p>
                バナー画像が表示されるTwitterのタイムラインは、流し読みしている人がほとんどです。そのため、
                <strong>
                  動画のバナーが流れてきても、最後まで見てもらえません。
                </strong>
              </p>
              <p>
                画像は一目で内容を確認できますが、動画は内容を理解するのに数秒かかってしまうからです。
              </p>
              <p>その他にも動画バナーのデメリットはいくつかあります。</p>
              <p>
                ・画像よりも時間とコストがかかる。
                <br />
                ・パケット通信量を気にする人は再生しない。
                <br />
                ・音が鳴るのを気にする人は再生しない。
              </p>
              <p>
                上記のようなデメリットから、動画はリツイートされにくくなります。
              </p>
              <h3 id="id8">・おすすめはGIF画像</h3>
              <p>
                キャンペーンのバナーでオススメなのは簡易的なアニメーションの「GIF画像」です。
                <br />
                動きを最小限に抑えて、強調したい一部分だけを動かすことで、見た人の興味を惹くことができます。
              </p>
              <p>
                キャラクターを無意味に動かすよりも、商品名や金額部分など参加者の利益につながる部分をアニメーションにした方が、参加意欲を高めることができます。
                <br />
                （例：Amazonギフト券<strong>1,000円</strong>）。
              </p>
              <p>
                複雑なアニメーションは避けるようにしましょう。
                <br />
                パッと見て一目でキャンペーン内容を理解できることが大切です。
              </p>
            </div>
            <div>
              <h2 id="id9">3.キャンペーンのリマインド投稿をする</h2>
              <p>
                内容を決めてバナー設定すれば、いよいよキャンペーンの開催です。
                <br />
                期間中は投稿をそのまま放置してはいけません。公式アカウントで必ず
                <br />
                リマインド投稿をするようにしましょう。
              </p>
              <h3 id="id10">・キャンペーン期間中は毎日リマインド投稿</h3>
              <p>
                「リマインド投稿は面倒」と二の足を踏む方もいらっしゃいますが、やり方はとても簡単です。公式アカウントでキャンペーン告知の投稿を「引用ツイート」するだけです。
              </p>
              <p>
                最初の告知ツイートだけでは、キャンペーン自体を忘れ去られてしまいます。リマインド投稿で、常に参加を呼びかけるようにしましょう。
              </p>
              <p>
                <strong>
                  投稿のタイミングはターゲット層によって変わりますが、一般的には「朝の出勤時間」、「お昼休憩」、「夜の退勤時間」の3回がオススメ。
                </strong>
                ランチで携帯を見る時、通勤で電車に乗っている時など、投稿しやすいタイミングに合わせて投稿するのがいいでしょう。
              </p>
              <p>
                投稿のテキスト内容は、バナーを見てほしいので簡潔な文章で構いません。「今日までですよ」、「出来るだけ早く参加してくださいね」など行動を促す内容の方が、効果が高まります。
              </p>
              <p>
                また、そのときトレンドになっているハッシュタグを上手く投稿に反映すれば、さらに多くの人に見てもらえます。どんなハッシュタグが流行っているのかも、普段からチェックしておくといいでしょう。
              </p>
              <h3 id="id11">・引用ツイートを代行する便利なサービス</h3>
              <p>
                日々の業務に追われるキャンペーン担当者の皆さんにとって、毎回忘れずに引用ツイートするのは結構難しいのではないでしょうか。
              </p>
              <p>
                PARKLoTには引用ツイートを代行するオプションプランもあります。
                <a
                  href="https://www.park-lot.com/contact/"
                  target="_blank"
                  rel="noreferrer"
                >
                  お問い合わせ
                </a>
                からお気軽にご相談ください。
              </p>
            </div>
            <div>
              <h2 id="id12">4.キャンペーン投稿を「固定ツイート」に設定する</h2>
              <p>
                キャンペーン開催期間中には、公式アカウントのプロフィール上部にキャンペーン投稿を「固定ツイート」に設定するようにしましょう。
                <br />
                （キャンペーンよりも優先したいツイートがある場合を除く）
              </p>
              <p>
                というのも、キャンペーンに参加しようとする人は、主催者のプロフィールを確認して「
                <strong>信頼できる主催者かどうか</strong>
                」をチェックするからです。
              </p>
              <p>
                キャンペーン投稿を「固定ツイート」に設定しておけば、公式アカウントに訪れた人がプロフィールを見たあとで、すぐにキャンペーン内容を確認できます。このように効率的な導線を設計しておけば、即リツイートにつながることもあります。
              </p>
              <p>
                キャンペーン投稿が他の投稿で埋もれるのも防げるので、必ず固定しておくことをオススメします。
              </p>
            </div>
            <div>
              <h2 id="id13">5. 当選＆落選報告に返信する</h2>
              <p>
                公式アカウントの「中の人」として、フォロワーとコミュニケーションを取り親近感を持ってもらうことは重要です。
              </p>
              <p>
                しかし、フォロワーとの自然なコミュニケーションは、炎上のリスクなどもはらんでおり、ツイートのさじ加減が非常に難しいといえるでしょう。
              </p>
              <p>
                実は、それよりも遥かに簡単な方法で自然な形で顧客とコミュニケーションを取れる方法があります。
              </p>
              <p>
                <strong>
                  それはキャンペーン開催期間中の当選報告・落選報告ツイートに返信することです。
                </strong>
              </p>
              <p>
                【返信テキストの例】
                <br />
                ・当選した人　→「当選おめでとうございます！今回ご応募された○○キャンペーンに当選いたしました！」
                <br />
                ・落選した人
                →「今回は落選という形ですがキャンペーンはまだまだ続いておりますので、ぜひまたご参加ください！」
                <br />
              </p>
              <p>定型文を作っておけば、すぐに送付することができます。</p>
              <p>
                一方、参加者のことを考えると、当選or落選した時の報告を簡単にできるように、主催者側が配慮しておく必要があります。
              </p>
              <p>
                大手企業では、この報告機能がオプションで設定されており、5万円以上の料金がかかることも珍しくありません。
              </p>
              <p>
                その点、PARKLoTは当選or落選の報告を簡単にできる機能が「標準」（無料）で実装されています。主催者と参加者の両方にとって使いやすいツールだといえるでしょう。
              </p>
            </div>
            <div>
              <h2 id="id14">6.継続的にキャンペーンを開催する</h2>
              <p>
                ここまでキャンペーン期間中の重要ポイントを説明してきましたが、残念ながら単発のキャンペーンでは十分な効果にはつながりません。
              </p>
              <p>
                <strong>
                  プレゼント内容を変えながら、継続的にキャンペーンを開催していくことが重要です。
                </strong>
              </p>
              <p>
                効果的な開催期間と頻度については、
                <a
                  href="https://www.park-lot.com/blog/fan-marketing/"
                  target="_blank"
                  rel="noreferrer"
                >
                  プロが教えるTwitterインスタントウィン・キャンペーンのやり方
                </a>
                の記事で説明しているので参考にしてくださいね。
              </p>
            </div>
            <div>
              <h2 id="id15">Twitterキャンペーンの費用でお悩みの方へ</h2>
              <p>
                PARKLoTでは、主催者が継続的にキャンペーンを開催できるように、使用料金をかなり抑えて設定しています。
              </p>
              <ul className="blog__border-list">
                <p>
                  【他社の料金設定】
                  <br />
                  ・初期費用　　5〜50万円
                  <br />
                  ・月額費用　　20〜50万円
                  <br />
                  ※月額費用がなく毎回3〜5万円の費用が発生するツールもありました。
                </p>
                <p>
                  <strong>
                    【PARKLoTの料金設定】
                    <br />
                    ・初期費用　　無料
                    <br />
                    ・月額費用　　5万円〜
                    <br />
                  </strong>
                  <small>
                    3ヶ月契約：60,000円 /月 (税込66,000円)、6ヶ月契約：55,000円
                    /月 (税込60,500円)
                    <br />
                    年間契約 ：50,000円 /月 (税込55,000円)　　※
                    3ヶ月以上の契約が必要です。
                  </small>
                </p>
              </ul>
              <p>
                このリーズナブルな料金設定を見て、思わず不安になった人もいらっしゃるのではないでしょうか。この料金設定には明確な理由があります。
              </p>
              <p>
                <strong>
                  それは、PARKLoTが少数精鋭のチームで自社開発されているから。
                </strong>
                <br />
                大企業（他社）の開発現場では、大規模なチームが組まれ、一つのプロセスで10人以上の決済が発生するなど、様々な人的コストが発生します。
              </p>
              <p>
                <strong>
                  PARKLoTは、最少人数の優れたエンジニアが責任を持って完成させているので、不要な人件費を全てカットできました。
                </strong>
              </p>
              <p>
                キャンペーン開始まで最短2分です。
                Twitterキャンペーンを始めたい方は、PARKLoTをぜひご検討ください！
              </p>
            </div>
            <Link to={`/`}>
              <div className="blog__text-center">
                <img
                  layout="constrained"
                  placeholder="tracedSVG"
                  alt="PARKLoT(パークロット)"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/brand/blue.png"
                  maxwidth={300}
                  className="m-auto"
                />
              </div>
            </Link>
            <p>
              <a
                href="https://hub.park-lot.com/document-download"
                target="_blank"
                rel="noreferrer"
              >
                お得にTwitterキャンペーンをはじめる！資料ダウンロードはこちら
              </a>
            </p>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="junko" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/comparison-no-tool"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンで本当にツールは必要なのか？
                    お金をかけずに手動でできる方法は？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/instant-win-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterインスタントウィンとは？リツイートキャンペーンとの違いは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのフォロワーが少ない状態でのSNS運用は効果が薄い？プレゼントキャンペーンを実施して一気にフォロワーを獲得しましょう！
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default MaximizeCampaign
